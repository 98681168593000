<template>
    <div class="config-panel">
        <div class="config-item">
            <div class="config-item-label">苹果保质期</div>
            <CSSelect>
                <select
                    v-model="actionAppleConfig[101].value"
                    :style="{
                        color:
                            actionAppleConfig[101].value === ''
                                ? '#999'
                                : '#444',
                    }"
                >
                    <option value="">请选择</option>
                    <option v-for="n in 5" :key="n+2" :value="n+2">
                        {{ n+2 }}天
                    </option>
                </select>
            </CSSelect>
        </div>
        <div class="config-item">
            <div class="config-item-label">兑换比例</div>
            <input
                type="text"
                placeholder="1-999"
                maxlength="3"
                v-model="actionAppleConfig[102].value"
            />
            个兑换1元
        </div>

        <div class="config-item">
            <div class="config-item-label">发放方式</div>
            <CSRadio v-model="actionAppleConfig[102].isManualGrant"
                     :items="{false: '人工（线下添加用户好友发放红包）', true: '自动（线上公众号自动给用户发放红包）'}"/>
        </div>
        <div v-if="actionAppleConfig[102].isManualGrant" class="config-item" style="margin-left: 368px;">
            <div>苹果兑换的现金大于等于</div>
            <CSSelect style="margin: 0 10px;">
                <select v-model="actionAppleConfig[102].manualReviewMount">
                    <option value="">请选择</option>
                    <option v-for="n in 100" :value="n" :key="n">
                        {{ n }}元
                    </option>
                </select>
            </CSSelect>
            ，需要人工审核后，点击发放，公众号才会给用户发放红包。
        </div>
        <div class="config-item">
            <div class="config-item-label">
                <button class="btn btn-primary" @click="saveConfig" style="width: 50px;height: 30px;padding: 0px">保存</button>
            </div>
        </div>
    </div>
</template>
<script>
import {setAppleConfigUrl, getAppleConfigUrl} from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import CSRadio from "@/components/common/CSRadio";

export default {
    name: "AppleCommonConfig",
    components: {
        CSRadio,
        CSSelect,
    },
    data() {
        return {
            isManualGrant: false,
            actionAppleConfig: {
                101: {
                    value: "",
                },
                102: {
                    value: "",
                    manualReviewMount: '',
                    isManualGrant: false,
                },
            },
        };
    },
    created() {
        this.getAppleConfig();
    },
    methods: {
        getAppleConfig() {
            this.$fly
                .get(getAppleConfigUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    for (let key in this.actionAppleConfig) {
                        if (this.actionAppleConfig.hasOwnProperty(key)) {
                            this.actionAppleConfig[key].value =
                                res.data[key].value;
                            if (key == 102) {
                                this.actionAppleConfig[key].isManualGrant =
                                    res.data[key].isManualGrant;
                                this.actionAppleConfig[key].manualReviewMount =
                                    res.data[key].manualReviewMount;
                            }
                        }
                    }
                });
        },
        // 触发保存操作
        async saveConfig() {
            let configItems = [],
                regionCode = this.$vc.getCurrentRegion().code;
            if (this.actionAppleConfig[101].value === '') {
                this.$vc.message('请选择苹果保质期')
                return;
            }
            if (this.actionAppleConfig[102].value === '') {
                this.$vc.message('请输入兑换比例')
                return;
            }
            for (let action in this.actionAppleConfig) {
                if (this.actionAppleConfig.hasOwnProperty(action)) {
                    const config = this.actionAppleConfig[action];
                    let params = {};
                    if (action == 102) {
                        params = {
                            manualReviewMount: config.manualReviewMount,
                            isManualGrant: config.isManualGrant,
                        }
                    }
                    configItems.push({
                        regionCode,
                        actionType: Number(action),
                        value: config.value,
                        method: 1,
                        enable: true,
                        ...params,
                    });
                }
            }
            this.setUpConfig(configItems);
        },
        // 设置苹果配置
        setUpConfig(items) {
            this.$fly
                .post(setAppleConfigUrl, {
                    items,
                })

                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("保存成功");
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.config-panel
    background #fff
    padding 20px 16px
    min-height 400px
    color #444
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)

    .config-item
        display flex
        flex-flow row nowrap
        align-items center

        &-label
            width 70px
            font-size 14px
            margin-right 45px
            text-align right

        input
            height 30px
            padding 0 5px
            width 88px
            border-radius 3px
            border 1px solid #979797
            margin-right 4px

        select
            option
                color #444

        &:not(:last-of-type)
            margin-bottom 10px
</style>
